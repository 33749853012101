'use client';

import { lusitana } from '@/app/ui/fonts';
import {
  AtSymbolIcon,
  KeyIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import Image from 'next/image';
import { notoSans } from '@/app/ui/fonts';
// import { Button } from './button';
import { useFormState, useFormStatus } from 'react-dom';
import { authenticate, googleSignIn } from '@/app/lib/actions';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
// import GoogleButton from 'react-google-button';
import GoogleButton from 'react-google-signin-button';
import './google-login-btn.css';

export default function LoginForm() {
  const [errorMessage, dispatch] = useFormState(authenticate, undefined);

  return (
    // <form action={dispatch} className="space-y-3">
    //   <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-4 pt-8">
    //     <h1 className={`${lusitana.className} mb-3 text-2xl`}>
    //       Please log in to continue.
    //     </h1>
    //     <div className="w-full">
    //       <div>
    //         <label
    //           className="mb-3 mt-5 block text-xs font-medium text-gray-900"
    //           htmlFor="email"
    //         >
    //           Email
    //         </label>
    //         <div className="relative">
    //           <input
    //             className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
    //             id="email"
    //             type="email"
    //             name="email"
    //             placeholder="Enter your email address"
    //             required
    //           />
    //           <AtSymbolIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
    //         </div>
    //       </div>
    //       <div className="mt-4">
    //         <label
    //           className="mb-3 mt-5 block text-xs font-medium text-gray-900"
    //           htmlFor="password"
    //         >
    //           Password
    //         </label>
    //         <div className="relative">
    //           <input
    //             className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
    //             id="password"
    //             type="password"
    //             name="password"
    //             placeholder="Enter password"
    //             required
    //             minLength={6}
    //           />
    //           <KeyIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
    //         </div>
    //       </div>
    //     </div>
    //     <LoginButton />
    //     <div
    //       className="flex h-8 items-end space-x-1"
    //       aria-live="polite"
    //       aria-atomic="true"
    //     >
    //       {errorMessage && (
    //         <div>
    //           <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
    //           <p className="text-sm text-red-500">{errorMessage}</p>
    //         </div>
    //       )}
    //     </div>

    //     <div className="text-sm text-[#6c7794]">
    //       {`Don't have an account?`}
    //       <a href="/register" className="text-[#0000eebb]">
    //         Sign up
    //       </a>
    //     </div>
    //   </div>
    // </form>

    <div className="flex h-full items-center justify-center p-4">
      <Card className="w-full max-w-md rounded-none">
        <CardHeader className="flex items-center justify-center space-y-1 pb-6 pt-12">
          <div className="flex items-center gap-2">
            <Image src="/logo-2.svg" alt="logo" width={35} height={35} />
            <span className="text-[1.5rem] font-semibold">PATTERN VISTA</span>
          </div>
        </CardHeader>
        <CardContent className="px-16 pb-12">
          <form action={dispatch} className="space-y-6">
            <h1 className="text-center text-2xl font-semibold">
              Please log in to continue
            </h1>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email" className="text-[1rem]">
                  Email
                </Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  // value={formData.email}
                  required
                  className="focus:border-[#ff6f20]"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password" className="text-[1rem]">
                  Password
                </Label>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  // value={formData.password}
                  required
                  className="focus:border-[#ff6f20]"
                />
                <div>
                  <Link
                    href="/recover-pwd"
                    className="text-[1rem] text-muted-foreground hover:text-primary"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <Button
                type="submit"
                className="w-full bg-orange-400 text-[1rem] hover:bg-orange-500"
              >
                Log in
              </Button>
              {/* <Button
                variant="outline"
                className="w-full border-orange-400 text-orange-400 hover:bg-orange-400/10 hover:text-orange-500"
                asChild
              >
                <Link href="/register">Sign up</Link>
              </Button> */}
              <div>
                <span className="text-[1rem] text-muted-foreground">
                  {`Don't have an account? `}
                </span>
                <Link
                  href="/register"
                  className=" text-[1rem] text-orange-400 hover:text-orange-500"
                >{`Sign up`}</Link>
              </div>

              <div
                className="flex h-8 items-end space-x-1"
                aria-live="polite"
                aria-atomic="true"
              >
                {errorMessage && (
                  <div className="mt-2 flex ">
                    <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-500" />
                    <p className="text-[0.875rem] text-red-500">
                      {errorMessage}
                    </p>
                  </div>
                )}
              </div>
              <div className="mx-auto flex justify-center">
                <GoogleButton
                  className={'border-1 border-orange-400'}
                  onClick={(e) => {
                    e.preventDefault(); // 关键：阻止事件冒泡
                    e.stopPropagation();
                    googleSignIn();
                  }}
                />
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

function LoginButton() {
  const { pending } = useFormStatus();
  return (
    <Button className="mt-4 w-full" aria-disabled={pending}>
      Log in <ArrowRightIcon className="ml-auto h-5 w-5 text-gray-50" />
    </Button>
  );
}
